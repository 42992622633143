main {
  transition: all 1s ease-in-out;

  &.active {
    transition: all 1s ease-in-out;
  }
}

.authBtns {
  .btn {
    transition: all 0.2s ease-in-out;
    left: -0.4rem;
  }

  &:hover {
    .btn {
      top: 3.5rem;
      opacity: 1;
    }
  }
}
.langBtns {
  .btn {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    .btn {
      top: 3.5rem;
      opacity: 1;
    }
  }
}

.activeNav {
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  top: 0;
  transition: all 0.2s ease-in-out;
  z-index: 40;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: transparent I !important;
}