.quiz-container {
  width: 100%;
  min-height: 40vh;
  margin-left: auto;
  margin-right: auto;
}

.active {
  display: block; // Show the active question
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: transparent;
}

.next-prev {
  border-radius: 30px;
  border: solid 1px transparent;
  height: 40px;
  padding: 0 40px;
  font-size: 17px;
  cursor: pointer;
  transition: 0.1s all ease;
}

/* CSS for slide animations */
.slide-in-right {
  animation: slideInRight 0.3s forwards;
}

.slide-out-left {
  animation: slideOutLeft 0.3s forwards;
}

@keyframes slideInRight {
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-10%);
  }
}

.active_input::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  top: 7px;
  right: 7px;
}

.active_input::before {
  background-color: var(--primary-color);
}

.block-div{
  display: block;
}
.block-div.end{
  display: contents;
}
@media(max-width:600px){
  .grid.myGrid {
    display: block;
  }
  .block-div {
    margin-bottom: 1rem;
  }
  .block-div.end {
    display: block;
  }
}
.file-div, .file-name{
  width: 220px;
}
.file-name{
  text-align: left;
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media(max-width:575px){
  .quiz-container{
    margin-top: 6rem;
  }
  .aspect-\[18\/9\] {
    aspect-ratio: unset;
  }
}