// .projectHero {
//     background-image: linear-gradient(to right, #000 1%, rgba(0, 0, 0, 0) 50%);
//     background-size: cover;
//     background-position: bottom;
//   }

// play button animation

.playContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  .button {
    display: inline-block;
    position: relative;
  }
  .button.is-play {
    background-color: #fff;
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  .button.is-play .button-outer-circle {
    background: rgba(255,255,255,0.2);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .button.is-play .button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }
  .button.is-play .button-icon .triangle {
    -webkit-animation: fadeIn 7s ease;
            animation: fadeIn 7s ease;
  }
  .button.is-play .button-icon .path {
    stroke-dasharray: 90;
    stroke-dashoffset: 0;
    -webkit-animation: triangleStroke 1s;
            animation: triangleStroke 1s;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  .has-scale-animation {
    -webkit-animation: smallScale 2s infinite;
            animation: smallScale 2s infinite;
  }
  .has-delay-short {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes triangleStroke {
    from {
      stroke-dashoffset: 90;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes triangleStroke {
    from {
      stroke-dashoffset: 90;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes smallScale {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(2.5);
      opacity: 0;
    }
  }
  @keyframes smallScale {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(2.5);
      opacity: 0;
    }
  }


// play button animation ends


.show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: RGBA(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 1rem 1.5rem;
    width: 24rem;
    border-radius: 0.5rem;
}

textarea::placeholder {
    margin-top: 20px;
}

.react-tel-input .flag-dropdown {
    border: none !important;
    background-color: transparent !important;
}

.react-tel-input .form-control {
    border: none !important;
    width: auto !important;
}