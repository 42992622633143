.aminities {
  &.AmenitiesActive {
    overflow-y: auto;
    height: 10rem;
    z-index: 999;
  }
}

.aminitiesCheckbox .checkmark:after {
  left: 5px;
  top: 1.2px;
  width: 5px;
  height: 11px;
}

.bedRoom {
  .checkBox input:checked ~ .checkmark:after {
    display: none;
  }
}
