#DeveloperSwiperImg {
    padding: 0px 2rem;
    object-fit: contain;
    aspect-ratio: 3/2;
    mix-blend-mode: color-burn;
}

// .mySwiperDeveloper div div{
//     // border-right: 1px solid #000000;
//     margin: 0px !important;
// }