.react-tel-input .flag-dropdown {
    top: 0 !important;
}

.form-control {
    height: 100%;
    width: 100%;
}

.react-tel-input .form-control {
    width: 100% !important;
    height: 100% !important;
}

.mySwiperCarasole .swiper-button-next:after{
    color: #333;
    right: -35%;
    position: absolute;
    overflow: visible !important;
}

.mySwiperCarasole .swiper-button-prev:after{
    color: #333;
    left: -35%;
    position: absolute;
}

.swiper-button-prev, .swiper-button-next {
  color: transparent !important;
}

.mySwiperCarasole {
    padding: 0px 20px;
}

/* range 2 */
.range2 {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: #D4B071;
  }
  
  .range2::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none; 
    height: 15px;
    width: 15px;
    background-color: #D4B071;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;
  }
  
  .range2::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #D4B071;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;
  }
  
  .range2::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 10px rgb(212,176,113, .2)
  }
  .range2:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 13px rgb(212,176,113, .2)
  }
  .range2:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 13px rgb(212,176,113, .2)
  }
  
  .range2::-moz-range-thumb:hover {
    box-shadow: 0 0 0 10px rgb(212,176,113, .2)
  }
  .range2:active::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgb(212,176,113, .2)
  }
  .range2:focus::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgb(212,176,113, .2)
  }


  .react-tel-input .flag-dropdown.open {
    background: transparent !important;
  }
  