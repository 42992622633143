.custom-cluster img {
  display: none;
}

.custom-cluster {
  display: flex;
  justify-self: center;
  align-items: center;
  position: relative;
}

.custom-cluster div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto !important;
  width: 30px !important;
  height: 30px !important;
  line-height: normal !important;
  background: #fff;
  font-size: 16px !important;
  border-radius: 9999px;
}

.custom-cluster div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #d4b071; /* Specify the color of the outline */
  border-radius: 50%;
  animation: pulse 2s infinite; /* Adjust the animation duration as needed */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  70% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.gm-style-iw-d {
  max-width: 20rem;
}

.gm-style .gm-style-iw-c,
.gm-style-iw-d {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-ui-hover-effect span,
.gm-ui-hover-effect {
  display: none !important;
}
