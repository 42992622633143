#OffPlan {
    .tags.flex.items-center {
      .buy,
      .rent {
        display: none;
      }
    }
  
    .completion-status {
      display: none;
    }
  
    
  }

  .agent-sticky {
    position: sticky;
    top: 10rem;
  }