.block-feild {
	display: block;
	width: 33.33%;
}
.block-feild.end{
    margin-bottom: 2rem;
    width: 100%;
}
.block-feild textarea{
    margin-bottom: 0!important;
}
@media( max-width:767px) {
	.block-feild {
		width: 100%;
	}
}
