
.mainSlide {
     .slick-list::before{
          display: none;
     }
}
.PropertyDetails {
     footer {
          margin-top: 0;
          z-index: 10;
          position: relative;
     }
}

.imgAvatar img {
     transform: scale(2);
     transform-origin: top center;
 }
.mainPropertySlider {
     .slick-list::before {
          display: none;
     }

     .slick-slider {
          height: 100%;
     }

     .slick-next {
          right: 1.5rem!important;
          background: transparent !important;

          &::before {
                    content: '';
                    font-size: 3rem;
                    background: url(https://cdn-icons-png.flaticon.com/128/271/271228.png);
                    width: 3rem;
                    background-size: cover;
                    height: 3rem;
                    background-position: center;
                    position: absolute;
                    top: 0;
                    filter: invert(1);
                    right: 1rem!important;
               }
     }

     .slick-prev {
          left: 1.5rem;
          background: transparent !important;

          &::before {
               content: ''!important;
                    font-size: 3rem;
                    background: url(https://cdn-icons-png.flaticon.com/512/271/271220.png);
                    width: 3rem;
                    background-size: cover;
                    height: 3rem;
                    background-position: center;
                    position: absolute;
                    top: 0;
                    filter: invert(1);
          }
     }

     .slick-dots {
          bottom: 5.5rem;

          li {
               button:before {
                    opacity: 1;
                    color: white;
                    width: 1rem;
                    height: 1rem;
                    font-size: 1rem;
               }

               &.slick-active button:before {
                    color: #CDAC62;

               }
          }
     }

}

.alsoHave {
     .slick-list::before {
          display: none;
     }
}

[dir=rtl] {
     .mainPropertySlider {
          .slick-next {
               left: auto !important;
          }

          .slick-prev {
               left: 25px !important;
          }
     }
}

@media only screen and (max-width: 767px) {

     .mainPropertySlider {
          .slick-dots {
               bottom: 9.5rem; 
          }

     }
}

@media only screen and (max-width: 1280px) {

     .mainPropertySlider {
          .slick-dots {
               bottom: 6.5rem;
          }

     }
}

// styles.scss
$scrollbar-width: 0px;
$scrollbar-track-color: transparent ;
$scrollbar-thumb-color: rgba(0, 0, 0, 0.5);
$scrollbar-thumb-border-radius: 3px;

.scrollable-container {

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: $scrollbar-thumb-border-radius;
  }
}

.height3{
     height: 46px;
}