.swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
  font-size: 25px;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-prev {
  background-color: #ffffff !important;
  opacity: 1 !important;
}

.mySwiperAmenities img {
  border-radius: 3rem;
}
