.auth{
          min-height: 70vh;
}
.or {
          &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    left: 0;
                    background: #CDAC62;
                    height: 1px;
                    top: 50%;
          }
}