textarea{
    height: 2.5rem;
  margin-top: 1rem;
  min-height: 2.5rem;
  max-height: max-content;
}
.error-token{
  margin-bottom: 2rem;
}
.error-token.right{
  text-align: right;
}
@media(max-width:575px){
  .error-token.right{
    text-align: center;
  }
}
.error-token-center{
  text-align: center;
  margin: 1rem 0;
}